<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A group of students were given the following set of equipment and guiding question (at the
        top of the poster). Match the statements to the parts of scientific argumentation.
      </p>

      <p class="mb-3"><b>Equipment</b>:</p>

      <p class="mb-2">Objects: blue cylinder, clear cube, black cylinder</p>
      <p class="mb-2">Tools to measure volume: graduated cylinder, spill can, & calipers</p>
      <p class="mb-5">Digital balance</p>

      <p class="mb-7 pl-6">
        <v-img style="max-width: 493px" src="/img/assignments/uciWhiteboard.jpg" />
      </p>

      <p class="mb-n3">a) Evidence:</p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) Reasoning:</p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-2-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) Claim:</p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-2-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S2Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options: [
        {
          text: 'Density, an intensive property that characterizes a substance, is similar for the blue & clear object',
          value: 'a',
        },
        {
          text: 'Density determination by 2 different methods',
          value: 'b',
        },
        {
          text: 'The blue & clear objects are the same substance',
          value: 'c',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
